<template>
  <div class="content md flex-box vertical">
    <data-list
      class="flex-grow"
      ref="dataList"
      :url="url"
      :query="query"
      :requestConfig="{ noTempleFilter: true }"
      entity-name="文章"
      hide-show-detail
      hide-edit
      hide-delete
      show-row-num
      :columns="columns"
      :search-key-type="searchKeyType"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入志工姓名查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择注册小组筛选"
            @change="updateList"
            :options="groupOptions"
            v-model="form.group"
            allowClear
            show-search
            option-filter-prop="children"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.title" placeholder="输入文章标题查询" allowClear></a-input>
        </a-form-model-item>
      </template>
      <template v-slot:action="{ item }">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn" @click.stop="auditRow(item)" v-action:audit>审核</a>
        </div>
      </template>
    </data-list>
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      title="审核"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
      @cancel="cancel"
    >
      <a-form-model
        class="item-form"
        ref="editForm"
        :model="single"
        :rules="rules"
        :labelCol="modalForm.labelCol"
        :wrapperCol="modalForm.wrapperCol"
        :layout="modalForm.layout"
        :labelAlign="modalForm.labelAlign"
      >
        <a-form-model-item label="审核结果">
          <a-radio-group v-model="single.status" :options="auditOptions"></a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { dataConvertOptions, getKeyTitle } from "../../../common/js/tool";
import { auditStatusOptions, auditOptions } from "../../../common/hr/volunteer";
import { getTemple } from "../../../common/js/storage";

export default {
  name: "ShareArticleAudit",
  data() {
    return {
      url: "/admin/volunteer-article",
      searchKeyType: {
        name: 2,
        group: 2,
        title: 1,
      },
      columns: [
        { title: "文章标题", dataIndex: "title" },
        {
          title: "文章封面",
          dataIndex: "img",
          customRender: (text) => {
            return <img class="avatar" src={text} />;
          },
        },
        { title: "发布时间", dataIndex: "create_time" },
        {
          title: "志工注册小组",
          customRender: (text) => {
            return <span>{text.volunteer?.volunteerGroup?.name || "-"}</span>;
          },
        },
        {
          title: "志工姓名",
          customRender: (text) => {
            return <span>{text.volunteer?.name || "-"}</span>;
          },
        },
        {
          title: "审核状态",
          dataIndex: "status",
          customRender: (text) => {
            return <span>{getKeyTitle(auditStatusOptions, text, "value", "label")}</span>;
          },
        },
      ],
      single: {},
      rules: {
        status: [{ required: true, message: "请选择审核结果", trigger: "change" }],
      },
      modalForm: { layout: "vertical", labelAlign: "left" },
      groupOptions: [],
      auditOptions,
      visible: false,
    };
  },
  computed: {
    query() {
      let temple = getTemple();
      return `&filter[volunteer_article.temple_id]=${temple}&sort=-id&expand=volunteer`;
    },
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {
    this.getGroup();
  },
  methods: {
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    getList() {
      this.dataList.getList();
    },
    updateList() {
      this.dataList.updateList();
    },
    auditRow(item) {
      const { id, status } = item;
      this.single = { id, status };
      this.visible = true;
    },
    cancel() {
      this.single = {};
      this.visible = false;
    },
    confirm() {
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = this.single;
          this.$axios({ url: `${this.url}/${form.id}`, method: "PATCH", data: form }).then(() => {
            this.$message.success("更新成功");
            this.visible = false;
            this.getList();
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.txt-btn.grey {
  cursor: not-allowed;
}
</style>
